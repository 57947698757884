@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Grandstander:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  /* font-family: "IBM Plex Sans", sans-serif; */
  font-family: "Grandstander", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #052421;
  /* background: linear-gradient(to right, #317e33, #3a963d, #317e33); */
}

input{
  /* font-family: "IBM Plex Sans", sans-serif; */
  font-family: "Grandstander", cursive;
}

textarea{
  /* font-family: "IBM Plex Sans", sans-serif; */
  font-family: "Grandstander", cursive;
}

button{
  /* font-family: "IBM Plex Sans", sans-serif; */
  font-family: "Grandstander", cursive;
}