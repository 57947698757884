.Header{
    display: flex;
    justify-content: center;
    height: 80px;
    width: 100%;
    /* margin-left: 5%; */
    position: absolute;
    /* top: 15px; */
    z-index: 1000;
    /* border-radius: 40px; */
    /* background-color: #3a963d; */
    /* border: 2px solid #000; */
    transition: 0.3s ease;

}

.Header_Black{
    background-color: #052421;
    box-shadow: 0px 0px 10px #1dd4c558;
}

.Header span{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 90%;
}

.Header h1{
    all: unset;
    font-size: 35px;
    font-weight: 600;
    color: #f7d239;
    text-shadow: 2px 2px #000;
}

.Header ul{
    all: unset;
    display: flex;
    list-style: none;
    margin-right: 27.5px;
}

.Header ul li{
    margin-left: 27.5px;
    transition: 0.2s ease;
    cursor: pointer;
    font-size: 18px;
    user-select: none;
    color: #000;
    font-weight: 500;
}

.Header_Black ul li{
    color: #fff;
    transition: 0.3s ease;
}

.Header ul li:hover{
    color: #c72727;
}

.Header button{
    height: 45px;
    width: 110px;
    border: none;
    outline: none;
    box-shadow: 3px 3px 0px #000;
    color: #fff;
    background-color: #c72727;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 600;
    transition: 0.2s ease;
    cursor: pointer;
}

.Header button:hover{
    transform: translate(3px, 3px);
    box-shadow: 0px 0px 0px #000;
}

.Footer{
    display: flex;
    flex-direction: column;
}

.Footer_Con1{
    display: flex;
    justify-content: center;
    height: fit-content;
    background-color: #ffbf23;
}

.Footer span{
    margin: 80px 0;
    width: 85%;
}

.Footer_Con2{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #000;
    background-color: #17a79c;
}

.Footer h2{
    all: unset;
    font-size: 25px;
    font-weight: 600;
}

.Footer_Title{
    display: grid;
    grid-template-columns: calc(20% - 20px) calc(20% - 20px) calc(20% - 20px) calc(20% - 20px) calc(20% - 20px);
    justify-content: space-between;
    margin-top: 40px;
}

.Footer_Body{
    display: grid;
    grid-template-columns: calc(20% - 20px) calc(20% - 20px) calc(20% - 20px) calc(20% - 20px) calc(20% - 20px);
    justify-content: space-between;
    margin-top: 20px;
}

.Footer h3{
    all: unset;
    font-size: 18px;
    font-weight: 500;
}

.Footer p{
    all: unset;
    line-height: 25px;
    font-size: 16px;
}

.Footer a{
    margin: 0;
    line-height: 25px;
    font-size: 16px;
    color: #000;
}

.Footer_Social{
    font-size: 22px;
    font-weight: 600;
    text-decoration: none;
    width: 120px;
}

.Footer_Social_Con{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

@media screen and (max-width: 770px) {
    .Header ul{
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .Header h1{
        font-size: 28px;
    }
    .Header{
        height: 60px;
    }
    .Header button{
        height: 40px;
    }
}