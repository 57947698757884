.Hero{
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
    /* background-image: url('../assets/grass.png'); */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
}

.Hero span {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 85%;
}

.Hero_Left {
    display: flex;
    flex-direction: column;
    height: 60%;
    width: 100%;
    justify-content: center;
}

.Hero_Left_Img{
    display: none;
    height: 50vh;
    width: 100%;
    background-image: url('../assets/pepe.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
}

.Hero_Right {
    height: 70vh;
    flex: 1;
    background-image: url('../assets/pepe.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
}

.Hero_Bottom{
    display: flex;
    align-items: center;
    width: 100vw;
    height: 80px;
    position: absolute;
    bottom: 0px;
    background-color: #40a443;
    overflow: hidden;
    white-space: nowrap;
}

.Hero_Bottom h3{
    height: 20px;
    margin: 0 20px;
    font-size: 24px;
    color: #f7d239;
    text-shadow: 2px 2px #000;
}

.Hero_Bottom_Con{
    display: flex;
    animation: 25s scroll infinite linear;
}

@keyframes scroll {
	from { 
        transform: translateX(0);
    }
	to {
        transform: translateX(-100%);
    }
}

.Hero h2{
    all: unset;
    font-size: 10vw;
    font-weight: 600;
    color: #f7d239;
    text-shadow: 3px 3px #000;
    text-align: center;
}

.Hero p{
    max-width: 250px;
    font-size: 20px;
    margin: 0;
    margin-top: 20px;
    color: #fff;
    line-height: 30px;
}

.Hero button{
    height: 45px;
    min-width: 110px;
    border: none;
    outline: none;
    box-shadow: 3px 3px 0px #000;
    color: #fff;
    background-color: #c72727;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 600;
    transition: 0.2s ease;
    cursor: pointer;
    align-self: flex-end;
    margin-left: 140px;
    margin-bottom: 80px;
}

.Hero button:hover{
    transform: translate(3px, 3px);
    box-shadow: 0px 0px 0px #000;
}

.Hero_Con{
    display: flex;
    align-items: center;
    width: 75%;
    position: absolute;
    bottom: 80px;
}

.Hero_Left_Con{
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Product{
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
}

.Product_Curve{
    width: 100%;
    aspect-ratio: 4.5;
    background-image: url('../assets/curve1.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-top: -15vh;
}

.Product_Splash{
    height: 130px;
    width: 130px;
    background-image: url('../assets/splash.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: 40px;
    top: 40px;
}

.Product_Splash1{
    height: 130px;
    width: 130px;
    background-image: url('../assets/splash.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: 100px;
    bottom: 40px;
}

.Product_Splash2{
    height: 130px;
    width: 130px;
    background-image: url('../assets/splash.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    right: 40px;
    top: 80px;
}

.Product_Splash3{
    height: 130px;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../assets/splash.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    right: 40px;
    bottom: 40px;
}

.Product_Splash_Smoke{
    height: 250px;
    width: 250px;
    background-image: url('https://i.makeagif.com/media/9-17-2016/98Y0aS.gif');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: -50%;
    left: -30%;
    align-self: center;
    mix-blend-mode: difference;
}

.Product_Con{
    display: flex;
    justify-content: center;
    flex: 1;
    /* background-color: #338736; */
    padding: 130px 0;
}

.Product span{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    background-color: #40a443;
    padding: 50px 0;
    border-radius: 9px;
}

.Product_Butterfly{
    height: 100px;
    width: 100px;
    position: absolute;
    top: -60px;
    right: -68px;
    background-image: url('../assets/butterfly.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.Product h2{
    all: unset;
    font-size: 50px;
    font-weight: 400;
    font-weight: 600;
    color: #f7d239;
    text-shadow: 3px 3px #000;
    position: relative;
}

.Product p{
    all: unset;
    font-size: 20px;
    line-height: 30px;
    width: calc(100% - 100px);
    color: #fff;
    margin-top: 20px;
}

.Services{
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    position: relative;
}

.Services_Branch {
    height: 300px;
    width: 35%;
    position: absolute;
    right: -30px;
    top: 0px;
    background-image: url('../assets/branch.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: rotate(10deg);
}

.Services_Branch1 {
    height: 300px;
    width: 35%;
    position: absolute;
    left: -30px;
    bottom: 0px;
    background-image: url('../assets/branch.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    -webkit-transform: rotate(-10deg) scaleX(-1);
    transform: rotate(-10deg) scaleX(-1);
}

.Services span{
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 70px 0;
}

.Services h2{
    all: unset;
    font-size: 50px;
    font-weight: 400;
    font-weight: 600;
    color: #f7d239;
    text-shadow: 3px 3px #000;
    text-align: center;
    margin-bottom: 60px;
}

.Services h3{
    all: unset;
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
}

.Services h4{
    all: unset;
    font-size: 16px;
    line-height: 25px;
    margin-top: 5px;
}

.Services p{
    all: unset;
    font-size: 18px;
    line-height: 27px;
    margin-top: 20px;
    text-align: center;
    color: #fff;
}

.Services_Con{
    display: grid;
    grid-template-columns: calc(25% - 20px) calc(25% - 20px) calc(25% - 20px) calc(25% - 20px);
    justify-content: space-between;
    width: 100%;
    margin-top: 70px;
}

.Services_Item{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
}

.Services_Item img{
    width: 100%;
    aspect-ratio: 0.777;
    object-fit: cover;
    border-radius: 10px;
    border: 2px solid #000;
}

.Roadmap_Con{
    width: 100%;
    display: flex;
}

.Roadmap_Con1{
    width: 100%;
    display: none;
}

.Roadmap_Con_Left{
    display: flex;
    flex: 1;
}

.Roadmap_Con_Box{
    display: flex;
    flex-direction: column;
    width: calc(85% - 40px);
    height: fit-content;
    background-color: #e2d0a5;
    box-shadow: 6px 6px 0px #513b20;
    border-radius: 10px;
    padding: 20px;
    margin-top: -20px;
}

.Roadmap_Con_Box_Line{
    flex: 1;
    height: 0px;
    border: 1px #fff dashed;
    margin-top: 10px;
}

.Roadmap_Con_Middle{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Roadmap_Con_Middle_Circle{
    width: 15px;
    min-height: 15px;
    border-radius: 50%;
    border: 3px solid #fff;
    background-color: #c72727;
}

.Roadmap_Con_Middle_Line{
    width: 3px;
    height: 100%;
    background-color: #fff;
}

.Roadmap_Con_Right{
    display: flex;
    flex: 1;
}

.Industry{
    height: fit-content;
    display: flex;
    justify-content: center;
}

.Industry span{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 70px 0; */
    margin-bottom: 70px;
}

.Industry_Left{
    width: 50%;
    aspect-ratio: 1;
    background-image: url('https://png.pngtree.com/png-vector/20240622/ourmid/pngtree-cute-frog-2d-illustration-vector-logo-png-image_12809265.png');
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
}

.Industry_Right{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
}

.Industry h2{
    all: unset;
    font-size: 50px;
    font-weight: 400;
    font-weight: 600;
    color: #f7d239;
    text-shadow: 3px 3px #000;
}

.Industry h3{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    margin-left: 20px;
}

.Industry h4{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    margin-left: 15px;
}

.Industry h5{
    display: none;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    margin-left: 15px;
}

.Industry p{
    all: unset;
    width: 550px;
    font-size: 20px;
    margin-top: 20px;
    color: #fff;
    line-height: 30px;
    text-align: center;
}

.Industry p b{
    font-size: 25px;
    color: #c72727;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
}

.Industry_Con{
    height: 75px;
    width: 750px;
    display: flex;
    align-items: center;
    margin-top: 50px;
    border-radius: 8px;
    background-color: #17a79c;
    border: 2px solid #1dd4c6;
    color: #000;
}

.Industry_Card_Con{
    display: flex;
    margin-top: 60px;
    gap: 0 20px;
}

.Industry_Card{
    display: flex;
    flex-direction: column;
    height: 330px;
    width: 260px;
    border-radius: 13px;
    background-color: #f7d139ac;
    border: 2px solid #1dd4c6;
    transform: rotate(10deg);
    cursor: pointer;
    transition: 0.2s ease;
}

.Industry_Card1{
    transform: rotate(-10deg);
}

.Industry_Card:hover{
    transform: rotate(0deg);
}

.Industry_Card_Con1{
    aspect-ratio: 1;
    width: calc(100% - 30px);
    margin: 15px;
    border-radius: 8px;
    background-color: #17a79c;
    /* outline: 2px solid #1dd4c6; */
    background-image: url('../assets/token-1.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.Industry_Card_Con2{
    aspect-ratio: 1;
    width: calc(100% - 30px);
    margin: 15px;
    border-radius: 8px;
    background-color: #17a79c;
    /* outline: 2px solid #1dd4c6; */
    background-image: url('../assets/token-2.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.Industry_Card_Con3{
    aspect-ratio: 1;
    width: calc(100% - 30px);
    margin: 15px;
    border-radius: 8px;
    background-color: #17a79c;
    /* outline: 2px solid #1dd4c6; */
    background-image: url('../assets/token-3.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.Industry_Card_Con4{
    aspect-ratio: 1;
    width: calc(100% - 30px);
    margin: 15px;
    border-radius: 8px;
    background-color: #17a79c;
    /* outline: 2px solid #1dd4c6; */
    background-image: url('../assets/token-4.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.Industry_Card_Txt1{
    font-size: 20px;
    margin: 0 15px;
    margin-top: 10px;
    color: #c72727;
    font-weight: 500;
    text-shadow: 1.5px 1.5px #000;
    text-align: center;
}

.Industry_Card_Txt2{
    font-size: 35px;
    font-weight: 600;
    margin: 0 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #000;
    text-align: center;
}

.Industry_Con1{
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #17a79c;
    border: 1px solid #1dd4c6;
    margin-right: 20px;
    border-radius: 6px;
}

.Industry_Con_Icon{
    margin-right: 10px;
    margin-left: 20px;
    cursor: pointer;
}

.Choose{
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.Choose_Curve{
    width: 100%;
    aspect-ratio: 4.5;
    background-image: url('../assets/curve2.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-top: -5vh;
}

.Choose_Con{
    display: flex;
    justify-content: center;
    flex: 1;
    background-color: #fed7ef;
    padding-bottom: 130px;
    margin-top: -7.5vh;
}

.Choose span{
    display: flex;
    flex-direction: column;
    width: 70%;
}

.Choose h2{
    all: unset;
    font-size: 35px;
    font-weight: 400;
}

.Choose h3{
    all: unset;
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
}

.Choose p{
    all: unset;
    font-size: 20px;
    line-height: 27px;
    width: 50%;
    margin-top: 30px;
}

.Choose_Con1{
    display: grid;
    grid-template-columns: calc(25% - 30px) calc(25% - 30px) calc(25% - 30px) calc(25% - 30px);
    justify-content: space-between;
}

.Choose_Item{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.Enquiry{
    height: fit-content;
    display: flex;
    justify-content: center;
}

.Enquiry span{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 70%;
    margin: 80px 0;
}

.Enquiry_Left{
    display: flex;
    flex-direction: column;
    width: 50%;
}

.Enquiry_Right{
    display: flex;
    flex-direction: column;
    width: 50%;
    position: relative;
}

.Enquiry h2{
    all: unset;
    font-size: 50px;
    font-weight: 600;
    color: #f7d239;
    text-shadow: 3px 3px #000;
    text-align: center;
}

.Enquiry p{
    all: unset;
    font-size: 18px;
    margin-top: 20px;
    color: #fff;
    text-align: center;
}

.Enquiry h3{
    all: unset;
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
}

.Enquiry_Con{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    width: 100%;
    position: relative;
}

.Enquiry_Con_Img_Con{
    padding: 30px;
    border-radius: 50%;
    border: 1px solid #000;
}

.Enquiry_Con_Img{
    height: 180px;
    width: 180px;
    background-image: url('../assets/pepe.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #031816;
    /* border: 1px solid #000; */
    border-radius: 50%;
}

.Enquiry_Con_Icon{
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #031816;
    color: #fff;
    /* border: 1px solid #000; */
    position: absolute;
    top: 0px;
    cursor: pointer;
    font-size: 30px;
    transition: 0.2s ease;
}

.Enquiry_Con_Icon:hover{
    transform: scale(120%);
}

.Enquiry_Con_Icon1{
    top: 60px;
    margin-left: 30%;
}

.Enquiry_Con_Icon2{
    top: 60px;
    margin-right: 30%;
}

.Enquiry_Con_Icon3{
    top: 0px;
    margin-left: 60%;
}

.Enquiry_Con_Icon4{
    top: 0px;
    margin-right: 60%;
}

@media screen and (max-width: 1100px) {
    .Hero button{
        display: none;
    }
    .Industry_Card{
        width: 250px;
    }
}

@media screen and (max-width: 1000px) {
    .Industry_Card_Con{
        display: grid;
        grid-template-columns: auto auto;
        gap: 30px;
    }
}

@media screen and (max-width: 900px) {
    /* .Enquiry_Con_Img{
        margin-top: 140px;
    } */
    .Hero p{
        font-size: 18px;
    }
}

@media screen and (max-width: 850px) {
    .Hero_Con{
        display: none;
    }
    .Hero_Left_Con{
        display: flex;
    }
    .Hero_Left{
        height: 100%;
    }
    .Hero p{
        max-width: 100%;
        text-align: center;
    }
    .Hero_Left_Img{
        display: flex;
    }
    .Hero{
        height: fit-content;
    }
    .Hero h2{
        margin-top: 150px;
    }
    .Hero_Bottom{
        bottom: -80px;
    }
    .Product_Splash{
        left: 10px;
        top: 40px;
    }
    
    .Product_Splash1{
        left: -40px;
        bottom: 40px;
    }
    
    .Product_Splash2{
        right: 10px;
        top: 80px;
    }
    
    .Product_Splash3{
        right: 10px;
        bottom: 40px;
    }
}

@media screen and (max-width: 770px) {
    .Product span{
        width: 85%;
        padding-top: 50px;
        padding-bottom: 30px;
    }
    .Services span{
        width: 85%;
        margin-top: 0px;
    }
    .Industry span{
        width: 85%;
    }
    .Industry_Con{
        flex-direction: column;
        width: 85%;
        padding: 20px 0;
    }
    .Industry_Con1{
        margin-right: 0px;
    }
    .Enquiry span{
        width: 85%;
    }
}

@media screen and (max-width: 700px) {
    .Industry h4{
        display: none;
    }
    .Industry h5{
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .Roadmap_Con{
        display: none;
    }
    .Roadmap_Con1{
        display: flex;
    }
    .Roadmap_Con_Box{
        width: calc(85% - 30px);
        margin-top: -20px;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 630px) {
    .Industry_Card_Con{
        width: 85%;
        display: grid;
        grid-template-columns: auto;
    }
    .Industry_Card{
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .Product h2{
        font-size: 40px;
    }
    .Product p{
        width: calc(100% - 40px);
        font-size: 15px;
        line-height: 25px;
    }
    .Services h2{
        font-size: 40px;
    }
    .Services p{
        font-size: 15px;
    }
    .Industry h2{
        font-size: 40px;
    }
    .Industry p{
        font-size: 15px;
        width: 100%;
        line-height: 22px;
    }
    .Enquiry h2{
        font-size: 40px;
    }
    .Enquiry p{
        font-size: 15px;
        line-height: 22px;
    }
}