.Hero1 {
  height: 100vh;
  margin: auto;
  background-color: #17a79c;
}

.Hero1 span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 50;
}

.Hero1_Img {
  height: 45vh;
  width: 45vh;
  background-image: url("../assets/pepe.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 50%;
  background-color: #149187;
  border: 3px solid #1dd4c6;
}

.Hero1_Txt{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Hero1_Social_Con{
  display: flex;
  width: 80%;
}

.Hero1_Social{
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #000;
  margin: 0 8px;
  margin-top: 30px;
  cursor: pointer;
}

.Hero1_Social:hover{
  animation: glow 2s linear infinite;
}

@keyframes glow {
  0%{
    box-shadow: 0px 0px 0px #1ed4c6;
  }
  50%{
    box-shadow: 0px 0px 20px #1ed4c6;
  }
  100%{
    box-shadow: 0px 0px 0px #1ed4c6;
  }
}

.Hero1_Social img{
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.Hero1_Social1{
  display: none;
  align-items: center;
}

.Hero1_Social2{
  display: flex;
  align-items: center;
}

.Hero1_Svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.Hero1 h1 {
  margin: 0;
  margin-top: -35px;
  font-size: 85px;
  font-weight: 600;
  color: #f7d239;
  text-shadow: 3px 3px #000;
  text-align: center;
  animation: zoom 1.5s infinite ease;
}

@keyframes zoom {
  0% {
    transform: scale(90%);
  }
  50% {
    transform: scale(100%);
  }
  100% {
    transform: scale(90%);
  }
}

.Hero1 p {
  /* display: flex; */
  width: fit-content;
  margin: 0;
  text-align: center;
  font-size: 24px;
  margin-top: 15px;
  line-height: 28px;
  animation: borderblink 0.7s infinite linear;
}

/* .Hero1_Cursor{
  height: 3px;
  width: 10px;
  background-color: #000;
  margin-top: 19px;
  animation: borderblink 0.8s infinite linear;
} */

@keyframes borderblink {
  0%{
    border-right: 1.5px solid transparent;
  }
  50%{
    border-right: 1.5px solid #000;
  }
  100%{
    border-right: 1.5px solid transparent;
  }
}

.Hero1 button {
  height: 45px;
  width: 210px;
  border: none;
  outline: none;
  box-shadow: 3px 3px 0px #000;
  color: #fff;
  background-color: #c72727;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 600;
  transition: 0.2s ease;
  cursor: pointer;
  margin-top: 30px;
}

.Hero1 button:hover {
  transform: translate(3px, 3px);
  box-shadow: 0px 0px 0px #000;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.out-top {
  animation: rotate 20s linear infinite;
  transform-origin: 13px 25px;
}
.in-top {
  animation: rotate 10s linear infinite;
  transform-origin: 13px 25px;
}
.out-bottom {
  animation: rotate 25s linear infinite;
  transform-origin: 84px 93px;
}
.in-bottom {
  animation: rotate 15s linear infinite;
  transform-origin: 84px 93px;
}

.Story {
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  padding: 100px 0;
}

.Story span {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  width: 75%;
  background-color: #031816;
  border: 2px solid #173a37;
  border-radius: 4px;
  padding: 50px 0;
}

.Story_Con {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  width: calc(100% - 100px);
  justify-content: space-between;
  margin-top: 20px;
}

.Story_Con1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Story_Con2{
  display: none;
  flex-direction: column;
  margin-top: 20px;
}

.Story button {
  height: 45px;
  width: calc(100% - 100px);
  border: none;
  outline: none;
  box-shadow: 2px 2px 0px #000;
  color: #fff;
  background-color: #c72727;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 30px;
  animation: zoom 3s linear infinite;
}

.Story h2 {
  all: unset;
  font-size: 50px;
  font-weight: 400;
  font-weight: 600;
  color: #f7d239;
  text-shadow: 3px 3px #000;
  position: relative;
  text-align: center;
}

.Story p {
  all: unset;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  text-align: center;
}

.Token {
  height: 850px;
  margin: auto;
  background-color: #17a79c;
  position: relative;
}

.Token_Svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 850px;
}

.Token span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 50;
}

.Token h2 {
  all: unset;
  font-size: 50px;
  font-weight: 400;
  font-weight: 600;
  color: #f7d239;
  text-shadow: 3px 3px #000;
}

.Token h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  margin-left: 20px;
}

.Token h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  margin-left: 15px;
}

.Token h5 {
  display: none;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  margin-left: 15px;
}

.Token p {
  all: unset;
  width: 550px;
  font-size: 20px;
  margin-top: 20px;
  color: #000;
  line-height: 30px;
  text-align: center;
}

.Graph {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;
  background-color: #17a79c;
  position: relative;
}

.Graph h6{
  all: unset;
  font-size: 50px;
  font-weight: 400;
  font-weight: 600;
  color: #f7d239;
  text-shadow: 3px 3px #000;
  position: absolute;
  text-align: center;
  top: 100px;
  z-index: 100;
}

.Graph_SVG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Graph span {
  width: fit-content;
  display: flex;
  flex-direction: column;
  z-index: 50;
  margin-top: 80px;
}

.Graph h3 {
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

.Graph h4 {
  margin: 0 10px;
  text-align: center;
  font-weight: 600;
  color: #fff;
  font-size: 20px;
}

.Graph h4 b {
  font-weight: 600;
  color: #1ed4c6;
}

.Graph_Con1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.Graph_Con1_Left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Graph_Con1_Right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2.5px;
}

.Graph_Con1_Left_Con {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 200px;
  display: flex;
  flex-direction: column;
  background-color: #000;
  border-radius: 10px;
}

.Graph_Con1_Left_Con1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 200px;
  background-color: #000;
  border-radius: 10px;
  margin-top: 2.5px;
  position: relative;
}

.Graph_Con1_Left_Con2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 200px;
  background-color: #000;
  border-radius: 10px;
  /* margin-top: 2.5px; */
  margin-left: 2.5px;
}

.Graph_Con1_Left_Arrow_Down {
  width: 0px;
  height: 50px;
  border: 1.5px solid #000;
  position: relative;
  margin-left: 100px;
}

.Graph_Con1_Left_Arrow_Right_Down {
  width: 150px;
  height: 86px;
  border-top: 3px solid #000;
  border-right: 3px solid #000;
  margin-left: 100px;
  position: absolute;
  right: -150px;
  top: 34px;
}

.Graph_Con1_Left_Arrow_Right_Down_Icon {
  position: absolute;
  bottom: 0px;
  right: 0px;
  transform: translate(calc(50% + 1.5px), calc(50% - 2px));
}

.Graph_Con1_Left_Arrow_Down_Icon {
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform: translate(-50%, 50%);
}

.Graph_Con1_Left_Arrow_Right {
  height: 0px;
  width: 50px;
  border: 1.5px solid #000;
  position: relative;
}

.Graph_Con1_Left_Arrow_Right_Icon {
  position: absolute;
  bottom: 0px;
  right: 0px;
  transform: translate(50%, 50%);
}

.Graph_Con1_Left_Inner {
  display: flex;
  align-items: center;
}

.Graph_Con2 {
  display: flex;
  width: 100%;
}

.Graph_Con2_Left {
  display: flex;
  flex-direction: column;
}

.Graph_Con2_Down_Left_Arrow {
  height: 100px;
  width: 50px;
  margin-left: 100px;
  border-bottom: 3px solid #000;
  border-left: 3px solid #000;
}

.Graph_Con2_Down_Arrow {
  width: 0px;
  height: 150px;
  border: 1.5px solid #000;
  position: relative;
  margin-left: 100px;
}

.Graph_Con2_Down_Arrow_Icon {
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform: translate(-50%, 50%);
}

.Graph_Con2_Middle {
  height: fit-content;
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.Graph_Con2_Middle_Con {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 100px;
  background-color: #1ed4c6;
  border: 3px solid #000;
  border-radius: 100% / 125% 125% 80% 80%;
  /* border-radius: 60px 50px 50px 60px; */
  margin-right: -13px;
  z-index: 100;
}

.Graph_Con2_Middle_Con2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 100px;
  background-color: #1ed4c6;
  border: 3px solid #000;
  border-radius: 100% / 125% 125% 80% 80%;
  margin-left: -13px;
  z-index: 100;
  position: relative;
}

.Graph_Con2_Middle_Con2_Up_Arrow {
  height: 173px;
  width: 0px;
  border: 1.5px solid #000;
  position: absolute;
  top: -179px;
  left: 47.5px;
}

.Graph_Con2_Middle_Con2_Up_Arrow_Icon {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(-50%, -50%);
}

.Graph_Con2_Middle_Con2_Down_Arrow {
  height: 190px;
  width: 0px;
  border: 1.5px solid #000;
  position: absolute;
  bottom: -193px;
  left: 47.5px;
}

.Graph_Con2_Middle_Con2_Down_Arrow_Icon {
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform: translate(-50%, 50%);
}

.Graph_Con2_Middle_Con1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 270px;
  height: 80px;
  background-color: #000;
  /* border-radius: 10px; */
  position: relative;
}

.Graph_Con2_Middle_Con1 h2 {
  margin: 0;
  font-size: 40px;
  font-weight: 600;
  color: #f7d239;
  text-shadow: 1px 1px #fff;
}

.Graph_Con2_Middle_Con1_Con {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  height: 180px;
  bottom: -180px;
  left: 50%;
  transform: translate(-50%, 0);
}

.Graph_Con2_Middle_Con1_Arrow {
  width: 0px;
  height: 50px;
  border: 1.5px solid #000;
  position: relative;
}

.Graph_Con2_Middle_Con1_Arrow1 {
  width: 0px;
  height: 30px;
  border: 1.5px solid #000;
  position: relative;
}

.Graph_Con3 {
  display: flex;
  flex-direction: column;
}

.Graph_Con3_Left_Con {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 200px;
  display: flex;
  flex-direction: column;
  background-color: #000;
  border-radius: 10px;
  margin-top: 2.5px;
}

.Graph_Con3_Bottom {
  display: flex;
}

.Graph_Con3_Bottom_Arrow {
  height: 70px;
  width: 350px;
  margin-left: 100px;
  border-left: 3px solid #000;
  border-bottom: 3px solid #000;
  position: relative;
}

.Graph_Con3_Bottom_Arrow_Icon {
  position: absolute;
  bottom: -1.5px;
  right: 1px;
  transform: translate(50%, 50%);
}

.Graph_Con3_Bottom_Con {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 200px;
  display: flex;
  flex-direction: column;
  background-color: #000;
  border-radius: 10px;
  margin-top: 35px;
  margin-left: 2.5px;
}

.Meme{
  height: fit-content;
  display: flex;
  justify-content: center;
}

.Meme span{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 70%;
  margin: 80px 0;
}

.Meme h2{
  all: unset;
  font-size: 50px;
  font-weight: 600;
  color: #f7d239;
  text-shadow: 3px 3px #000;
  text-align: center;
}

.Meme button{
  height: 45px;
  width: 310px;
  border: none;
  outline: none;
  box-shadow: 3px 3px 0px #000;
  color: #fff;
  background-color: #c72727;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 600;
  transition: 0.2s ease;
  cursor: pointer;
  margin-top: 30px;
}

.Meme_Con{
  display: grid;
  width: 100%;
  grid-template-columns: 33.3% 33.3% 33.3%;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.Meme_Img{
  width: 100%;
  margin-top: 30px;
  animation: zoom1 4s linear infinite;
}

.Meme_Img1{
  width: 100%;
  margin-top: 30px;
  animation: zoom2 1s linear infinite;
}

.Meme_Img2{
  width: 100%;
  margin-top: 30px;
  transform: rotate(-7deg);
  animation: zoom3 2s linear infinite;
}

@keyframes zoom1 {
  0% {
    transform: rotate(-6deg) scale(95%);
  }
  50% {
    transform: rotate(-6deg) scale(100%);
  }
  100% {
    transform: rotate(-6deg) scale(95%);
  }
}

@keyframes zoom2 {
  0% {
    transform: rotate(6deg) scale(95%);
  }
  50% {
    transform: rotate(6deg) scale(100%);
  }
  100% {
    transform: rotate(6deg) scale(95%);
  }
}

@keyframes zoom3 {
  0% {
    transform: rotate(-7deg) scale(95%);
  }
  50% {
    transform: rotate(-7deg) scale(100%);
  }
  100% {
    transform: rotate(-7deg) scale(95%);
  }
}

@media screen and (max-width: 1000px) {
  .Story span {
    width: 80%;
  }
  .Meme span{
    width: 80%;
  }
  .Token {
    height: 1150px;
  }
  .Token_Svg {
    height: 1150px;
  }
}

@media screen and (max-width: 900px) {
  .Story span {
    width: 90%;
  }
  .Meme span{
    width: 90%;
  }
  .Story_Con{
    display: none;
  }
  .Story_Con2{
    display: flex;
  }
  .Story p {
    margin: 0 35px;
    font-size: 18px;
    line-height: 27px;
  }
  .Story button{
    width: calc(100% - 70px);
    margin: 0 35px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 770px) {
  .Token {
    height: 1200px;
  }
  .Token_Svg {
    height: 1200px;
  }
  .Meme_Con{
    grid-template-columns: 50% 50%;
  }
  .Hero1_Social_Con{
    display: none;
  }
  .Hero1_Social1{
    display: flex;
  }
}

@media screen and (max-width: 750px) {
  .Graph {
    height: 700px;
  }
  .Graph span{
    transform: scale(90%);
  }
  .Graph h6{
    top: 50px;
  }
}

@media screen and (max-width: 630px) {
  .Token {
    height: 2000px;
  }
  .Token_Svg {
    height: 2000px;
  }
}

@media screen and (max-width: 600px) {
  .Token h4{
      display: none;
  }
  .Token h5{
      display: flex;
  }
  .Token p {
    width: 90%;
  }
}

@media screen and (max-width: 660px) {
  .Graph {
    height: 600px;
  }
  .Graph span{
    transform: scale(80%);
  }
}

@media screen and (max-width: 585px) {
  .Graph {
    height: 550px;
  }
  .Graph span{
    transform: scale(70%);
  }
  .Graph h6{
    top: 60px;
    font-size: 40px;
  }
  .Story h2{
    font-size: 40px;
  }
}

@media screen and (max-width: 510px) {
  .Graph {
    height: 450px;
  }
  .Graph span{
    transform: scale(60%);
  }
}

@media screen and (max-width: 500px) {
  .Hero1 h1 {
    font-size: 60px;
  }
  .Hero1 p {
    font-size: 20px;
  }
}

@media screen and (max-width: 440px) {
  .Graph {
    height: 400px;
  }
  .Graph span{
    transform: scale(50%);
  }
  .Meme_Con{
    grid-template-columns: 100%;
  }
}

@media screen and (max-width: 370px) {
  .Graph span{
    transform: scale(45%);
  }
}